import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';

import ImageGrid from '../ImageGrid/ImageGrid';
import RequestProductSheet from '../RequestProductSheet/RequestProductSheet';
import useStyles from './styles';

import bulletPointImage from '../../assets/ic_blur_circular_24px@3x.WebP';
import productSheetImage from '../../assets/marimetrics+product+sheet+v2.WebP';

/////////// Google Analytics 4 Element //////////////

const script = document.createElement('script');
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8BMNQD9XNC';
document.head.appendChild(script);

// Initialization of Google Tag Manager
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

// Configuration of Google Tag Manager
gtag('config', 'G-8BMNQD9XNC');

/////////////////////////////////////////////////////

const Body = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);  
    const closeModal = () => setOpen(false);
    return (
        <main className={classes.main}>
                <div className={classes.imageShips}>
                    <Typography className={classes.typography} variant="h1">We help industry leaders operate sustainably in the marine environment using data.</Typography>
                </div>
                <div className={classes.dividerDiv}>
                    <Typography className={classes.dividerTypography} variant="h1">We're bringing challenging measurements <em>in situ</em> for decision making in real time.</Typography>
                </div>
                <ImageGrid />
                <div className={classes.productDiv}>
                    <Typography className={classes.productTypography} variant="h1">Our Product</Typography>
                    <div className={classes.divCentered}>
                        <ul className={classes.productUl}>
                            <li className={classes.productLi}><img alt="Bullet point one" className={classes.bulletPoints} src={bulletPointImage}></img> Multiple chemical compounds measured simultaneously in a single package</li>
                            <li className={classes.productLi}><img alt="Bullet point two" className={classes.bulletPoints} src={bulletPointImage}></img> Unprecedented sensitivity</li>
                            <li className={classes.productLi}><img alt="Bullet point three" className={classes.bulletPoints} src={bulletPointImage}></img> Realtime measurement</li>
                        </ul>
                    </div>
                </div>
                <div className={`${classes.divCentered} ${classes.productSheetDiv}`}>
                    <img alt="Product sheet" className={`${classes.floatLeft} ${classes.productSheetImage}`} src={productSheetImage}></img>
                    <div className={classes.floatRight}>
                        <Typography className={classes.productSheetTypography} variant="h2">Product Spec Sheet</Typography>
                        <Button onClick={() => setOpen(true)} className={classes.productSheetButton} variant="outlined">Request Now</Button>
                        <Popup open={open} onClose={closeModal} className={classes.popup} position="center" modal>
                            <button className={classes.productSheetCloseButton} onClick={closeModal}>X</button>
                            <RequestProductSheet />
                        </Popup>
                    </div>
                </div> 
                <div className={classes.contactUsDiv}>
                        <div className={classes.divCentered}>
                            <Typography className={classes.contactUsTypography} variant="h2">Ready to find out how we can help you and your organization?</Typography>
                            <Button component={Link} to="/contact-us" className={classes.contactUsButton} variant="outlined">Contact Us</Button>
                        </div>
                </div> 
        </main>
    )
}

export default Body;
