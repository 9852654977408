import React, { useState } from 'react';

import { InputLabel, Button, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

/////////// Google Analytics 4 Element //////////////

const script = document.createElement('script');
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8BMNQD9XNC';
document.head.appendChild(script);

// Initialization of Google Tag Manager
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

// Configuration of Google Tag Manager
gtag('config', 'G-8BMNQD9XNC');

/////////////////////////////////////////////////////

const ContactUs = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');

    const classes = useStyles();

    return (
        <>
            <form action="https://formsubmit.co/info@marimetrics.com" method="POST" className={classes.contactUsForm}>
                    <input type="hidden" name="_next" value="https://marimetrics.no"></input>
                    <input type="hidden" name="_subject" value="Request a Demo"></input>
                    <Grid container spacing={2}>
                        <Typography className={classes.contactUsHeader} variant="h2">Request a Demo</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className={classes.inputHeader}>Name *</InputLabel>
                            <Grid item className={`${classes.floatLeft} ${classes.spacingWidthInput}`} xs={6} sm={6} md={6} lg={6}>
                                <input type="text" className={`${classes.wholeWidthInput} ${classes.singleLineInput} ${classes.input}`} value={firstName} required name="First Name" label="First name" onChange={(e) => setFirstName(e.target.value)}/>
                                <InputLabel className={classes.infoInputField}>First Name</InputLabel>
                            </Grid>
                            <Grid item className={`${classes.floatLeft} ${classes.spacingWidthInput} ${classes.marginLeft}`} xs={6} sm={6} md={6} lg={6}>
                                <input type="text" className={`${classes.wholeWidthInput} ${classes.singleLineInput} ${classes.input}`} value={lastName} required name="Last Name" label="Last name" onChange={(e) => setLastName(e.target.value)}/>
                                <InputLabel className={classes.infoInputField}>Last Name</InputLabel>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className={classes.inputHeader}>Email *</InputLabel>
                            <input type="text" className={`${classes.wholeWidthInput} ${classes.singleLineInput} ${classes.input}`} value={email} required name="Email" label="Email" onChange={(e) => setEmail(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className={classes.inputHeader}>Company *</InputLabel>
                            <input type="text" className={`${classes.wholeWidthInput} ${classes.singleLineInput} ${classes.input}`} value={company} required name="Company" label="Company" onChange={(e) => setCompany(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className={classes.inputHeader}>Message *</InputLabel>
                            <textarea className={`${classes.wholeWidthInput} ${classes.textarea} ${classes.input}`} value={message} required name="Messsage" label="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button className={classes.submitButton} type="submit" variant="contained" color="primary">Submit</Button>
                    </div>
            </form>
        </>
    )
}

export default ContactUs
