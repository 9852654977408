import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';

import logo from '../../assets/Logo+New+Marimetrics-10.WebP';
import slideMenuLogo from '../../assets/icon+white-03.png';
import useStyles from './styles'

const Navbar = () => {
    const classes = useStyles();
    const location = useLocation();

    let styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '34px',
            height: '18px',
            right: '80px',
            top: '36px',
        },
        bmBurgerBars: {
            background: 'silver',
        },
        bmCrossButton: {
            position: 'fixed',
            width: '16px',
            height: '16px',
            left: '22px',
            top: '36px',
        },
        bmCross: {
            background: 'silver',
        },
      }

    return (
        <>
            <AppBar position="fixed" className={classes.navBar} color="inherit">
                <Toolbar>
                    <MediaQuery minWidth={640}>
                        <Typography component={Link} to="/">
                            <img src={logo} alt="Marimetrics" className={classes.logo} />
                        </Typography>
                        <div className={classes.grow} />
                        { location.pathname === '/' && (
                        <Button variant="outlined" className={classes.button} component={Link} to="/contact-us">Contact Us</Button>
                        )}
                    </MediaQuery>
                    <MediaQuery maxWidth={639}>
                        <Typography component={Link} to="/">
                            <img src={logo} alt="Marimetrics" className={classes.logoSmall} />
                        </Typography>
                        <Menu styles={ styles } className={classes.hamburgerSlideMenu} right width={ "calc(100% - 60px )" }>
                            <img className={classes.slideMenuLogo} src={slideMenuLogo} alt="Marimetrics" />
                            <a href="/contact-us" className={classes.contactUsSlideMenu}>Contact Us</a>

                            <Typography className={classes.contactUsTrademarkSlideMenu}>©️ 2023 Marimetrics AS</Typography>
                        </Menu>
                    </MediaQuery>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navbar;
